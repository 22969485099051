'use strict';
var validator = require('base_global/validator');
var passwordErrorMessages = {};

var populateErrorMessages = require('base_global/validationMessage');

$(document).ready(function () {
    $(document).on('click', '.ds-pswd-toggle', function (e) {
        e.stopPropagation();
        var inputWrapper = $(this).closest('.ds-form-group');
        if ($(this).hasClass('eye-fill')) {
            $(this).addClass('d-none');
            inputWrapper.find('.eye-slash').removeClass('d-none');
            inputWrapper.find('.form-password').attr('type', 'text');
        } else {
            $(this).addClass('d-none');
            inputWrapper.find('.eye-fill').removeClass('d-none');
            inputWrapper.find('.form-password').attr('type', 'password');
        }
    });

    $(document).on('click', '.password-field-group', function () {
        $('.form-password').trigger('focus');
    });

    var myInput;
    var letter;
    var capital;
    var number;
    var length;

    // When the user starts to type something inside the password field
    $(document).on('keyup', '#ds-password', function () {
        // Validate lowercase letters

        if (myInput.val().match(/[a-z]/g)) {
            letter.removeClass('invalid');
            letter.addClass('valid');
        } else {
            letter.removeClass('valid');
            letter.addClass('invalid');
        }

        // Validate capital letters
        if (myInput.val().match(/[A-Z]/g)) {
            capital.removeClass('invalid');
            capital.addClass('valid');
        } else {
            capital.removeClass('valid');
            capital.addClass('invalid');
        }

        // Validate numbers
        if (myInput.val().match(/[0-9]/g)) {
            number.removeClass('invalid');
            number.addClass('valid');
        } else {
            number.removeClass('valid');
            number.addClass('invalid');
        }

        // Validate length
        if (myInput.val().length >= 6) {
            length.removeClass('invalid');
            length.addClass('valid');
        } else {
            length.removeClass('valid');
            length.addClass('invalid');
        }
        return true;
    });

    // When the user clicks on the password field, show the message box
    $(document).on('focusin click', '#ds-password', function () {
        myInput = $('#ds-password');
        letter = $('#ds-letter');
        capital = $('#ds-capital');
        number = $('#ds-number');
        length = $('#ds-length');
        $('#ds-password').removeClass('error is-invalid');
        $('.error-extended').each(function () {
            $(this).remove();
        });
        $('.message, .ds-rules-message')
            .removeClass('d-none')
            .addClass('ds-rules__transition');
    });

    // When the user clicks out of the password field, hide the message box
    $(document).on('focusout', '#ds-password', function () {
        setTimeout(() => {
            if (!$(document.activeElement).hasClass('form-password')) {
                letter = $('#ds-letter');
                capital = $('#ds-capital');
                number = $('#ds-number');
                length = $('#ds-length');

                if (
                    letter.hasClass('valid') &&
                    capital.hasClass('valid') &&
                    number.hasClass('valid') &&
                    length.hasClass('valid')
            ) {
                    $('.message, .ds-rules-message').removeClass(
                    'ds-rules__transition'
                );
                }
            }
        }, 100);
    });
});

module.exports = {
    passwordValidationHandler: function () {
        var $inputFormGroup = $('.ds-password-input');
        var $Password = $($inputFormGroup).find('#ds-password');
        populateErrorMessages(passwordErrorMessages, $Password);
        validator.init($inputFormGroup, {
            ...validator.ONKEYUP_ONFOCUSOUT_SETTINGS,
            ...validator.ENABLED_BUTTON_SETTINGS,
            messages: passwordErrorMessages
        });
    }
};
