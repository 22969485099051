'use strict';

var formValidation = require('base/components/formValidation');
var createErrorNotification = require('../components/errorNotification');
var validator = require('../validator');
var createAccErrorMessages = {};
var signInErrorMessages = {};
var passwordErrorMessages = {};
var tealiumEnable = require('int_tealium_sfra/tealiumEnable');
var populateErrorMessages = require('../validationMessage');
var utils = require('../utils');
const eshopLocator = 'eshopworld';
var JSencrypt = require('jsencrypt');
var isProcessing = false;
/**
 * Get Encrypted password value
 * @param {DOM} $passwordField - password field
 */
function encryptPasswordValue($passwordField) {
    var public_key = $('#pass-key').val();
    var passwordVal = $passwordField.val();
    var encrypt = new JSencrypt.JSEncrypt();
    encrypt.setPublicKey(public_key);
    var encryptedValue = encrypt.encrypt(passwordVal);
    $passwordField.val(encryptedValue);
}

/**
 * Handle Login Form Validation
 */
function loginFormValidation() {
    $('form.login').each(function () {
        var $signInForm = $(this);
        var $Email = $signInForm.find('#login-form-email');
        var $Password = $signInForm.find('#login-form-password');
        populateErrorMessages(signInErrorMessages, $Email);
        populateErrorMessages(signInErrorMessages, $Password);
        validator.init($signInForm, {
            ...validator.ONKEYUP_ONFOCUSOUT_SETTINGS,
            ...validator.ENABLED_BUTTON_SETTINGS,
            messages: signInErrorMessages
        });
    });
}

/**
 * Handle Forgot Password Form Validation
 */
function forgotPasswordFormValidation() {
    var $forgotPasswordForm = $('.reset-password-form');
    $forgotPasswordForm.each(function () {
        var $Email = $(this).find('#email');
        populateErrorMessages(signInErrorMessages, $Email);
        validator.init($(this), {
            ...validator.ONKEYUP_ONFOCUSOUT_SETTINGS,
            ...validator.ENABLED_BUTTON_SETTINGS,
            messages: signInErrorMessages
        });
    });
}

/**
 * Handle Sign Panel open
 * @param {string} url - Sign in AJAX url
 * @param {string} queryParam - Sign in AJAX url
 */
function signInPanelOpen(url, queryParam) {
    isProcessing = true;
    setTimeout(() => {
        $('.signin-mobile-overlay-layout').removeClass('d-none');
    }, 800);

    $.ajax({
        url: url + queryParam,
        type: 'get',
        success: function (data) {
            $('body').append(data);
            const elem = $('.signin-panel');
            var $mobileCloseIcon = $('#mobile-close-icon');
            if ($mobileCloseIcon.length) {
                $mobileCloseIcon.trigger('click');
            }
            setTimeout(() => {
                $('html').addClass('hide-scroll-and-lock');
                elem.addClass('show-signIn');
                isProcessing = false;
            }, 100);

            utils.formAutofillCustomization();
            loginFormValidation();
        }
    });
}

/**
 * @param {String} url - Cart-AddProduct url
 * @param {Object} pidsObj - array of pids and quantity
 */
function addStoredBasketToCurrentBasket(url, pidsObj) {
    let csrf_token = $('.csrf-token').data('csrf-value');
    var form = {
        pidsObj: JSON.stringify(pidsObj),
        isLoginMerge: true,
        csrf_token: csrf_token,
        currentLocation: window.location.pathname
    };
    $.ajax({
        url: url,
        type: 'POST',
        data: form,
        async: false,
        error: function (err) {
            console.error('Unable to restore storedBasket. ', err);
            tealiumEnable.getAnalyticsData('serverError', err.responseJSON.errorCode);
            if (err.responseJSON && err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            }
        }
    });
}

/**
 * @param {Object} elem - Button to be disabled
 */
function disableButton(elem) {
    elem.prop('disabled', true);
}

/**
 * @param {Object} elem - Button to be enabled
 */
function enableButton(elem) {
    elem.removeAttr('disabled');
}

module.exports = {
    skipNavNextFocusElement: function () {
        const focusableElements =
            'button:visible, [href]:visible, select:visible, textarea:visible, [tabindex]:not([tabindex="-1"]):visible';
        if ($('header').hasClass('nomenu_header')) {
            $('button.skip').attr('tabindex', '1');
        } else {
            $('button.skip').attr('tabindex', '0');
        }
        $(document).on('click', 'button.skip', function () {
            if ($('header').hasClass('nomenu_header')) {
                const firstFocusableElement = $('#checkout-main').find(focusableElements)[0];
                firstFocusableElement.focus();
            } else {
                const firstFocusableElement = $('#maincontent').find(focusableElements)[0];
                firstFocusableElement.focus();
            }
        });
    },
    createAccValidationHandler: function () {
        var $createAccForm = $('.registration');
        var $FirstName = $createAccForm.find('#registration-form-fname');
        var $LastName = $createAccForm.find('#registration-form-lname');
        var $Email = $createAccForm.find('#registration-form-email');
        var $Password = $createAccForm.find('#ds-password');
        populateErrorMessages(createAccErrorMessages, $FirstName);
        populateErrorMessages(createAccErrorMessages, $LastName);
        populateErrorMessages(createAccErrorMessages, $Email);
        populateErrorMessages(createAccErrorMessages, $Password);
        validator.init($createAccForm, {
            ...validator.ONKEYUP_ONFOCUSOUT_SETTINGS,
            ...validator.ENABLED_BUTTON_SETTINGS,
            messages: createAccErrorMessages
        });
    },
    signInValidationHandler: function () {
        loginFormValidation();
    },
    signinOpen: function () {
        $(document).on('click', '.signin-link, .checkout-signin-btn', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            var checkoutWrapper = $('#checkout-wrapper');
            var queryParam,
                url;
            if (checkoutWrapper.length) {
                queryParam = '?withinApp=true&pageType=shipping';
                url = $('.checkout-signin-btn').data('action-url');
            } else {
                queryParam = '?withinApp=true';
                url = $('.signin-link a').data('action-url');
            }
            if (!isProcessing) {
                signInPanelOpen(url, queryParam);
            }
            tealiumEnable.getAnalyticsData('creatAccountAndLoginEvent', 'signinOpen', false);
        });
    },
    signinClose: function () {
        $(document).on('click', '.js-signin-close-btn', function () {
            $('.signin-panel').removeClass('show-signIn');
            $('.modal-backdrop').removeClass('show');
            $('.signin-mobile-overlay-layout').addClass('d-none');
            setTimeout(() => $('.js-signin-panel, .ds-forgot-penal-modal').remove(), 800);
            setTimeout(() => $('html').removeClass('hide-scroll-and-lock'), 800);
            $('body').removeClass('modal-open');
        });

        $('body').click(function (event) {
            var emailInputElement = document.getElementById('login-form-email');
            var passwordInputElement = document.getElementById('login-form-password');
            if (emailInputElement !== document.activeElement && passwordInputElement !== document.activeElement) {
                if ($(event.target).closest('.ds-login-panel-modal').length && $(event.target).is('.ds-login-panel-modal')) {
                    $('.ds-login-panel-modal').removeClass('show-signIn');
                    setTimeout(() => $('.js-signin-panel').remove(), 800);
                    setTimeout(() => $('html').removeClass('hide-scroll-and-lock'), 800);
                    $('body').removeClass('modal-open');
                }
            }
        });
    },

    login: function () {
        $(document).on('click', '.btn-login', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            var form = $(this).closest('form.login');
            var defer = $.Deferred(); // eslint-disable-line
            if (!form.valid()) {
                return defer;
            }
            form.spinner().start();
            var url = form.attr('action');
            $('form.login').trigger('login:submit', e);
            var currentLocation = $(location).attr('pathname');
            var searchLogin = form.data('content-login');
            var searchCreateAccount = form.data('content-register');
            var confirmationPage = form.data('content-confirm');
            var trouleShootPage = form.data('content-troubleshootpage');
            var cartPage = form.data('content-cart');
            var checkoutPage = form.data('content-checkout');
            var sessionTimeoutPage = form.data('content-sessiontimeout');
            var lastVisitedPage = $('#lastVisitedPath').val();
            var custToken = $('#custToken').val();
            var confirmationApplePayPage = form.data('content-applepayconfirm');
            var signinPage = form.data('loginpage');
            var isSignedInFromCheckoutButton = $('.initiate-checkout_js').data('lastclicked') || utils.getQueryStringParams(window.location.href).login;
            var $this = $(this);
            var hasBasketMergedAfterLogin = false;
            disableButton($this);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    if (!data.success) {
                        formValidation(form, data);
                        $('form.login').trigger('login:error', data);
                        $('.alert-danger').append('<span class="icon-alert"></span>');
                        $('#login-form-email').addClass('error is-invalid');
                        $('#login-form-password').addClass('error is-invalid');
                        var fromModal = $('.ds-login-panel-modal').hasClass('show-signIn') ? true : false;
                        tealiumEnable.getAnalyticsData('creatAccountAndLoginEvent', 'login_failure', false, fromModal, data.email);
                        enableButton($this);
                        form.spinner().stop();
                    } else if (data.isMigratedCustomerFirstLogin) {
                        var $requestNewCustomerPasswordResetModal = $('#requestNewCustomerPasswordResetModal');
                        $('.signin-panel').removeClass('show-signIn');
                        $('.modal-backdrop').removeClass('show');
                        utils.openModal($requestNewCustomerPasswordResetModal);
                        $requestNewCustomerPasswordResetModal.find('#email').val(data.email);
                    } else {
                        var fromModal = $('.ds-login-panel-modal').hasClass('show-signIn') ? true : false;
                        tealiumEnable.getAnalyticsData('creatAccountAndLoginEvent', 'login', false, fromModal, data.email);
                        $('form.login').trigger('login:success', data);
                        let csrfSpan = $('.csrf-token');
                        if (data.csrfToken && csrfSpan.length) {
                            $('.csrf-token').data('csrf-value', data.csrfToken);
                        }

                        // Add stored basket to current basket
                        if (data.pidsObj && data.pidsObj.length > 0) {
                            addStoredBasketToCurrentBasket(data.addProductUrl, data.pidsObj);
                            hasBasketMergedAfterLogin = true;
                        }
                        if (currentLocation === searchLogin || currentLocation === searchCreateAccount || currentLocation === confirmationPage || currentLocation === trouleShootPage || currentLocation === confirmationApplePayPage || currentLocation === signinPage) {
                            location.href = data.redirectUrl;
                        } else if (currentLocation === cartPage && isSignedInFromCheckoutButton && data.success) { // eslint-disable-line
                            if ($('#siteName').val() === 'pvhckca') {
                                var checkoutURL = $('.btnCheckout').data('url');
                                checkoutURL += '?isCheckoutSignedIn=true';
                                $('.btnCheckout').attr('data-url', checkoutURL);
                                $('.btnCheckout').trigger('click');
                            } else if (isSignedInFromCheckoutButton) {
                                let queryParam = utils.getQueryStringParams(window.location.href);
                                if (queryParam.paymentMethod === 'expressPayment') {
                                    window.location.href = $('.initiate-checkout_js').data('cartpage');
                                } else {
                                location.href = `${checkoutPage}?rememberedGuestCheckout=true&proceed=true`;
                            }
                            }
                        } else if (currentLocation === checkoutPage) {
                            // if the basket items where merged upon login , go back to cart
                            if (hasBasketMergedAfterLogin) {
                                location.href = cartPage;
                                return;
                            }
                            location.href = `${checkoutPage}?stage=shipping&rememberedGuestCheckout=true`;
                        } else if (currentLocation === sessionTimeoutPage) {
                            if (custToken) {
                                location.href = lastVisitedPage !== 'null' && !lastVisitedPage.includes('Checkout-SessionTimeout') ? lastVisitedPage + `?Token=${custToken}` : document.referrer;
                            } else {
                                location.href = lastVisitedPage !== 'null' && !lastVisitedPage.includes('Checkout-SessionTimeout') ? lastVisitedPage : document.referrer;
                            }
                        } else {
                            window.location.reload();
                        }
                        // Remove login=true param once logged in
                        if (currentLocation === cartPage) {
                            history.replaceState({}, '', window.location.pathname);
                    }
                    }
                    if (data.accountLocked) {
                        window.location.href = data.redirectUrl;
                    }
                },
                error: function (data) {
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $('form.login').trigger('login:error', data);
                    }
                    enableButton($this);
                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    register: function () {
        $('.btn-createAcc').on('click', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            var form = $(this).closest('.registration');
            var defer = $.Deferred(); // eslint-disable-line
            var passwordValue = $('#ds-password').val();
            var validationResult = utils.passwordOnSubmitValidation(passwordValue);
            var $this = $(this);
            setTimeout(function () {
                var errorList = validationResult.errorMessage;
                if (!validationResult.isFormValid && passwordValue !== '') {
                    $('.ds-rules-message').addClass('d-none');
                    $('#ds-password-error').empty().show();
                    $('#ds-password').addClass('error is-invalid');
                    errorList.forEach(function (item) {
                        $('#ds-password-error').append(`<p class="error-extended">${item}</p>`);
                    });
                }
            }, 10);
            if (!form.valid() || !validationResult.isFormValid) {
                form.find('input.is-invalid').not('#ds-password').first().focus();
                enableButton($this);
                return defer;
            }
            var url = form.attr('action');
            var previousPath = document.referrer;
            form.spinner().start();
            $('form.registration').trigger('login:register', e);
            var searchLogin = form.data('content-login');
            var confirmationPage = form.data('content-confirm');
            disableButton($this);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    var addtoemaillist = data.addtoemaillist;
                    form.spinner().stop();
                    if (!data.success) {
                        $('form.registration').trigger('login:register:error', data);
                        $('#registration-form-email').attr('aria-invalid', 'true');
                        $('.registration #form-email-error').show();
                        formValidation(form, data);
                        enableButton($this);
                    } else {
                        tealiumEnable.getAnalyticsData('creatAccountAndLoginEvent', 'register', addtoemaillist, false, data.email);
                        $('form.registration').trigger('login:register:success', data);
                        if ((previousPath.indexOf(searchLogin) > -1) || (previousPath.length <= 0) || (previousPath.indexOf(confirmationPage) > -1) || (previousPath.length <= 0)) {
                            location.href = data.redirectUrl;
                        } else if (previousPath.includes(eshopLocator)) {
                            var redirectUrl = $('.btn-createAcc').data('urlredirect');
                            location.href = redirectUrl;
                        } else {
                            window.history.go(-1);
                        }
                    }
                },
                error: function (err) {
                    enableButton($this);
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                    }

                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    returnToSignIn: function () {
        $(document).on('click', '#returnToSignIn', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            $('.js-signin-panel').remove();
            var checkoutWrapper = $('#checkout-wrapper');
            var cartPageWrapper = $('#cart-page-wrapper');
            var queryParam,
                url;
            if (checkoutWrapper.length) {
                queryParam = '?withinApp=true&pageType=shipping';
                url = $('.checkout-signin-btn').data('action-url');
            } else if (cartPageWrapper.length) {
                queryParam = '?withinApp=true&pageType=cartpage';
                url = $(this).data('action-url');
            } else {
                queryParam = '?withinApp=true';
                url = $(this).data('action-url');
            }
            setTimeout(function () {
                $('.ds-forgot-penal-modal').removeClass('show');
            }, 300);

            signInPanelOpen(url, queryParam);

            setTimeout(function () {
                $('.ds-forgot-penal-modal').modal('hide');
                $('.ds-forgot-penal-modal').remove();
                $('.modal-backdrop').remove();
            }, 800);
        });
    },

    onResetPassword: function () {
        $(document).on('show.bs.modal', '#requestPasswordResetModal, #requestNewCustomerPasswordResetModal', function () {
            $('.signin-panel').removeClass('show-signIn');
            $('.signin-mobile-overlay-layout').addClass('d-none');
            forgotPasswordFormValidation();
        });
    },

    forgotPasswordEmailValidationHandler: function () {
        forgotPasswordFormValidation();
    },

    resetPassword: function () {
        const $toastdata = $('#forgot-password-expired').val();
        if ($('#forgot-password-expired').length) {
            utils.showErrorToast($toastdata);
            $('.ds-toast').addClass('ds-toast--mobile-spacing');
        }
        $(document).on('submit', '.reset-password-form', function (e) {
            $('header .ds-toast').remove();
            var form = $(this);
            e.preventDefault();
            e.stopImmediatePropagation();
            var url = form.attr('action');
            form.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $('.request-changed-title').text(data.receivedMsgHeading);
                        $('.account-unlocked-heading').text(data.receivedMsgHeading);
                        $('.request-password-body').empty()
                            .append('<p class="body-font md ds-secondary-text-heading ds-mb-24">' + data.receivedMsgBody + '</p>');
                        $('.returnToButton').hide();
                        if (!data.mobile) {
                            form.find('#submitEmailButton').addClass('d-none');
                            tealiumEnable.getAnalyticsData('checkEmailCTA');
                            form.find('#returnToSignIn').removeClass('d-none');
                        } else {
                            $('.send-email-btn').empty()
                                .html('<button onclick=location.href="'
                                    + data.returnUrl
                                    + '" class="back-to-sign-in ds-btn-block md">'
                                    + data.buttonText + '</button>'
                                );
                        }
                    }
                },
                error: function () {
                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    openChangePasswordModal: function () {
        $('#changePasswordLink').on('click', function (e) {
            var $this = $(this);
            e.preventDefault();
            var url = $this.attr('data-href');
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                success: function (data) {
                    $.spinner().stop();
                    if ($('#changePasswordModal').length) {
                        $('#changePasswordModal').remove();
                    }
                    $('body').append(data);
                    // $('#changePasswordModal').modal('show');
                    utils.openModal($('#changePasswordModal'));
                    var $inputFormGroup = $('.ds-password-input');
                    var $Password = $($inputFormGroup).find('#ds-password');
                    populateErrorMessages(passwordErrorMessages, $Password);
                    validator.init($inputFormGroup, {
                        ...validator.ONKEYUP_ONFOCUSOUT_SETTINGS,
                        ...validator.ENABLED_BUTTON_SETTINGS,
                        messages: passwordErrorMessages
                    });
                },
                error: function () {
                    $.spinner().stop();
                }
            });
            return false;
        });
    },

    changePassword: function () {
        $(document).on('click', '#changeSubmit', function (e) {
            var $this = $(this);
            var form = $this.closest('#newPasswordForm');
            e.preventDefault();
            e.stopImmediatePropagation();
            var defer = $.Deferred(); // eslint-disable-line
            if (!form.valid()) {
                return defer;
            }
            var url = $this.attr('data-actionurl');
            form.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: $('#newPasswordForm').serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                        var errorElem = `<div class="page-error" role="alert"><div class="alert-icon-wrap"><span class="icon-alert"></span></div><div class="message">${data.errorMessage}</div></div>`;
                        $('.changePasswordError').html(errorElem);
                        var $errorInput;
                        if (data.errorInput === 'currentPassword') {
                            $errorInput = $('#currentPassword');
                        }
                        if (data.errorInput === 'newPassword') {
                            $errorInput = $('.new-password');
                        }
                        $errorInput.val('');
                        $errorInput.addClass('is-invalid');
                    } else {
                        window.location.href = data.redirectUrl;
                    }
                },
                error: function () {
                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    errorMessage: function () {
        $(document).on('keyup', '#currentPassword', function (e) {
            $('.changePasswordError').empty();
        });
    },

    redirectToLogin: function () {
        $('#resetSubmit').on('click', function (e) {
            var form = $(this).closest('form');
            var fromCopy = form.clone();
            e.preventDefault();
            e.stopImmediatePropagation();
            var defer = $.Deferred(); // eslint-disable-line
            if (!form.valid()) {
                return defer;
            }

            // password Encryption based in custom preference
            var $Password = $('.form-password', fromCopy);
            var enableEncryption = $('#enableEncription').val();
            if (enableEncryption && enableEncryption === 'true') {
                encryptPasswordValue($Password);
            }
            var url = fromCopy.attr('action');
            form.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: fromCopy.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    form.submit();
                    window.location.href = data.redirectUrl;
                },
                error: function () {
                    $.spinner().stop();
                }
            });
            return false;
        });
    },

    clearResetForm: function () {
        $('#login .modal').on('hidden.bs.modal', function () {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    },

    redirectToMyAccount: function () {
        $('.content-benefit-incentive-order-placed .btn-my-account').on('click', function () {
            var url = $(this).data('url');
            window.location.href = url;
        });
    },

    showForgetPassowrdModal: function () {
        $(document).on('click', '#password-reset, #password-reset-sm', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            var url = $(this).attr('href');
            $.ajax({
                url: url,
                type: 'get',
                success: function (data) {
                    $('body').append(data);
                    $('#requestNewCustomerPasswordResetModal').remove();
                    tealiumEnable.getAnalyticsData('forgetPasswordCTA');
                    utils.openModal($('#requestPasswordResetModal'));
                }
            });
        });
    },
    forgetPasswordModelClose: function () {
        $('body').click(function (event) {
            if ($(event.target).closest('.ds-login-panel-modal').length && $(event.target).is('#requestPasswordResetModal')) {
                setTimeout(() => {
                    $('.js-signin-panel, .ds-forgot-penal-modal').remove();
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                }, 800);
            }
        });

        $(document).on('click', '.js-forget-pass-close-btn', function () {
            setTimeout(() => {
                $('.js-signin-panel, .ds-forgot-penal-modal').remove();
                $('body').removeClass('modal-open');
                $('html').removeClass('veiled');
            }, 800);
        });
    }
};
